<template>
    <ModalBox
        :is-active.sync="isModalActive"
        @close="isModalActive = false">
        <template #header>
            <h5 class="title has-text-centered is-5 is-fullwidth">
                {{ $t("common.plannedChangePassword.header.title") }}
            </h5>
        </template>

        <i18n path="common.plannedChangePassword.body.info">
            <template #differenceDay>
                <span class="has-text-weight-semibold">{{ differenceDay }}</span>
            </template>
        </i18n>

        <div class="flex is-justify-content-center">
            <img
                alt="ChangePassword"
                class="has-text-centered"
                src="@/assets/ChangePassword.svg">
        </div>

        <template #footer>
            <div class="flex is-justify-content-space-between is-flex-wrap-wrap">
                <b-button
                    size="is-small"
                    type="is-info is-light"
                    class="is-fullwidth"
                    @click="openChangePasswordModal">
                    {{ $t("common.plannedChangePassword.footer.button") }}
                </b-button>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox";
  import { mapGetters, mapState } from "vuex";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_MODAL } from "@core/store/mutation-constants";
  import { momentInst } from "@core/filters";
  import i18n from "@core/plugins/i18n";
  import momentjs from "moment";

  export default {
    name: "PlannedChangePasswordModal",
    components: { ModalBox },

    created () {
      if (this.hasForcedPasswordChange) {
        this.isPasswordModalActive = true;
        this.isModalActive = false;
      }
    },

    data () {
      return {
        isModalActive: true
      };
    },

    computed: {
      ...mapState({
        showPasswordChangeNotification: ({ auth }) => auth.showPasswordChangeNotification,
        nextPasswordChangeDate: ({ auth }) => momentInst(auth.nextPasswordChangeDate)
      }),

      ...mapFields("admin/profile/password", {
        fields: ["isPasswordModalActive"],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapGetters(["hasForcedPasswordChange"]),

      differenceDay () {
        return momentjs.duration(this.nextPasswordChangeDate.diff(momentInst())).locale(i18n.locale).humanize();
      }
    },

    methods: {
      openChangePasswordModal () {
        this.isPasswordModalActive = true;
        this.isModalActive = false;
      }
    },

    beforeDestroy () {
      // clearInterval(this.interval);
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .modal-card {
      &-head {
        padding-bottom: 10px;
      }
    }
  }

  .is-fullwidth {
    width: 100%;
  }
</style>
